<template>
    <div>
        <h1 class="blue clarity-margin-bottom-m">TYPOGRAPHY</h1>
        <div class="clarity-margin-bottom-s">
            <h2 class="blue clarity-margin-bottom-s">HEADINGS</h2>
            <h1 class="dark-grey">H1) Lorem ipsum dolor sit amet</h1>
            <h1 class="blue">H1) LOREM IPSUM DOLOR SIT AMET</h1>
            <br>
            <h2 class="dark-grey">H2) Lorem ipsum dolor sit amet</h2>
            <h2 class="blue">H2) LOREM IPSUM DOLOR SIT AMET</h2>
            <br>
            <h3 class="dark-grey">H3) Lorem ipsum dolor sit amet</h3>
            <h3 class="blue">H3) LOREM IPSUM DOLOR SIT AMET</h3>
            <h3 class="light-blue">H3) Lorem ipsum dolor sit amet</h3>
            <h3 class="grey">H3) LOREM IPSUM DOLOR SIT AMET</h3>
            <br>
            <h4 class="dark-grey">H4) Lorem ipsum dolor sit amet</h4>
            <h4 class="blue">H4) LOREM IPSUM DOLOR SIT AMET</h4>
            <h4 class="light-blue">H4) Lorem ipsum dolor sit amet</h4>
            <br>
            <h5 class="dark-grey">H5) Lorem ipsum dolor sit amet</h5>
            <h5 class="blue">H5) Lorem ipsum dolor sit amet</h5>
            <br>
            <h6 class="dark-grey">H6) Lorem ipsum dolor sit amet</h6>
            <h6 class="blue">H6) LOREM IPSUM DOLOR SIT AMET</h6>
        </div>

        <div class="clarity-margin-bottom-s">
            <h2 class="blue clarity-margin-bottom-s">SPECIAL HEADINGS</h2>
            <p class="special-heading-1">SP1) Lorem ipsum dolor sit amet</p>
            <p class="special-heading-2">SP2) Lorem ipsum dolor sit amet</p>
        </div>

        <div class="clarity-margin-bottom-s">
            <h2 class="blue clarity-margin-bottom-s">Body</h2>
            <p class="body-text">Suspendisse dignissim elit aliquet, egestas felis non, tincidunt turpis. Nulla ac justo et lectus tincidunt fermentum. Duis dui tellus, ultrices vel tortor eu, venenatis porttitor est. Duis auctor nisi non sapien placerat eleifend. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent tincidunt condimentum congue. Sed placerat ac magna in convallis. Nunc semper pellentesque tellus, ut molestie lectus convallis quis. Quisque in est sed odio eleifend mollis. Donec eu lacus id justo pulvinar consequat. Sed quis velit sodales, dictum neque a, ornare quam. In vestibulum, dui vitae fringilla congue, tortor velit molestie quam, a scelerisque ante diam in felis.</p>
        </div>
        <div class="clarity-margin-bottom-m">
            <p class="clarity-h2 text-5xl clarity-margin-bottom-s">SMALL</p>
            <p class="small-text">Suspendisse dignissim elit aliquet, egestas felis non, tincidunt turpis. Nulla ac justo et lectus tincidunt fermentum. Duis dui tellus, ultrices vel tortor eu, venenatis porttitor est. Duis auctor nisi non sapien placerat eleifend. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent tincidunt condimentum congue. Sed placerat ac magna in convallis. Nunc semper pellentesque tellus, ut molestie lectus convallis quis. Quisque in est sed odio eleifend mollis. Donec eu lacus id justo pulvinar consequat. Sed quis velit sodales, dictum neque a, ornare quam. In vestibulum, dui vitae fringilla congue, tortor velit molestie quam, a scelerisque ante diam in felis.</p>
            <br>
            <p class="extra-small-text">Suspendisse dignissim elit aliquet, egestas felis non, tincidunt turpis. Nulla ac justo et lectus tincidunt fermentum. Duis dui tellus, ultrices vel tortor eu, venenatis porttitor est. Duis auctor nisi non sapien placerat eleifend. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent tincidunt condimentum congue. Sed placerat ac magna in convallis. Nunc semper pellentesque tellus, ut molestie lectus convallis quis. Quisque in est sed odio eleifend mollis. Donec eu lacus id justo pulvinar consequat. Sed quis velit sodales, dictum neque a, ornare quam. In vestibulum, dui vitae fringilla congue, tortor velit molestie quam, a scelerisque ante diam in felis.</p>
        </div>
    </div>
</template>