<template>
    <div class="icon-text">
        <p class="clarity-h6 text-5xl clarity-margin-bottom-m">ICONS</p>
        <p>To use the Icon component add <code>&lt;Icon name="xxxx" altText="yyyy"&gt;&lt;/Icon&gt;</code> replacing xxxx with the icon filename minus the .png exstension and yyyy with the alt text.  If you want to render the large version of the icon add <code>isLarge</code> after the altText argument.</p>
        <p>For example:</p>
        <code>&lt;Icon name="inbox-icon" altText="Inbox"&gt;&lt;/Icon&gt;</code><br>
        <code>&lt;Icon name="inbox-icon" altText="Inbox" isLarge&gt;&lt;/Icon&gt;</code><br>
    </div>
    <h3>Custom Icons</h3>
    <div class="grid clarity-margin-bottom-ms icons">
        <div class="col">
            <p class="clarity-small-text">CGT-small Icon</p>
            <Icon name="CGT-small" altText="CGT-small-icon"></Icon>
            <br><br>
            <p class="clarity-small-text">CGT Icon</p>
            <Icon name="CGT" altText="CGT-icon" isLarge></Icon>
            <br><br>
            <p class="clarity-small-text">pdf icon</p>
            <Icon name="pdf-icon"></Icon>
            <br><br>
            <p class="clarity-small-text">leaf icon</p>
            <Icon name="leaf-icon"></Icon>
        </div>
        <div class="col">
            <p class="clarity-small-text">insta icon</p>
            <Icon name="instagram-icon"></Icon>
            <br><br>
            <p class="clarity-small-text">facebook icon</p>
            <Icon name="facebook-icon"></Icon>
            <br><br>
            <p class="clarity-small-text">linkedin icon</p>
            <Icon name="linkedin-icon"></Icon>
            <br><br>
            <p class="clarity-small-text">twitter icon</p>
            <Icon name="twitter-icon"></Icon>
            <br><br>
        </div>
        <div class="col">

        </div>
    </div>
    <h3>Google - Material Icons</h3>
    <div class="grid clarity-margin-bottom-ms icons">
        <div class="col">
            <div>
                <span class="material-icons-round">account_circle</span>
                <div>
                    <p class="clarity-small-text">account Icon</p>
                    <p> Google Material Icon: account_circle</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">add_circle</span>
                <div>
                    <p class="clarity-small-text">add Icon</p>
                    <p> Google Material Icon: add_circle</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">location_on</span>
                <div>
                    <p class="clarity-small-text">address Icon</p>
                    <p> Google Material Icon: location_on</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">archive</span>
                <div>
                    <p class="clarity-small-text">archive Icon</p>
                    <p> Google Material Icon: archive</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">navigate_before</span>
                <div>
                    <p class="clarity-small-text">arrow back Icon</p>
                    <p> Google Material Icon: navigate_before</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">navigate_next</span>
                <div>
                    <p class="clarity-small-text">arrow next Icon</p>
                    <p> Google Material Icon: navigate_next</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">article</span>
                <div>
                    <p class="clarity-small-text">article Icon</p>
                    <p> Google Material Icon: article</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">attachment</span>
                <div>
                    <p class="clarity-small-text">attached Icon</p>
                    <p> Google Material Icon: attachment</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">pie_chart</span>
                <div>
                    <p class="clarity-small-text">budgeting Icon</p>
                    <p> Google Material Icon: pie_chart </p>
                </div>
            </div>
            
            <div>
                <span class="material-icons-round">calculate</span>
                <div>
                    <p class="clarity-small-text">calculator Icon</p>
                    <p> Google Material Icon: calculate </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">today</span>
                <div>
                    <p class="clarity-small-text">calendar Icon</p>
                    <p> Google Material Icon: today</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round green">check_circle</span>
                <div>
                    <p class="clarity-small-text">check-done Icon</p>
                    <p> Google Material Icon: check_circle</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">check_circle_outline</span>
                <div>
                    <p class="clarity-small-text">check Icon</p>
                    <p> Google Material Icon: check_circle_outline</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round active">close</span>
                <div>
                    <p class="clarity-small-text">close-icon-active</p>
                    <p> Google Material Icon: close </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">close</span>
                <div>
                    <p class="clarity-small-text">close icon</p>
                    <p> Google Material Icon: close</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">credit_card</span>
                <div>
                    <p class="clarity-small-text">credit card icon</p>
                    <p> Google Material Icon: credit_card</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">delete_forever</span>
                <div>
                    <p class="clarity-small-text">delete icon</p>
                    <p> Google Material Icon: delete_forever</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">description</span>
                <div>
                    <p class="clarity-small-text">documents icon </p>
                    <p> Google Material Icon: description</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">search</span>
                <div>
                    <p class="clarity-small-text">search icon </p>
                    <p>Google Material Icon: search </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">fact_check</span>
                <div>
                    <p class="clarity-small-text">wealth plan icon </p>
                    <p>Google Material Icon: fact_check </p>
                </div>
            </div>
        </div>
        <div class="col">
            <div>
                <span class="material-icons-round">edit</span>
                <div>
                    <p class="clarity-small-text">edit icon </p>
                    <p>Google Material Icon: edit</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">email</span>
                <div>
                    <p class="clarity-small-text">email icon </p>
                    <p>Google Material Icon: email</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round red">error</span>
                <div>
                    <p class="clarity-small-text">error icon </p>
                    <p>Google Material Icon: error</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">expand_less</span>
                <div>
                    <p class="clarity-small-text">expand less icon </p>
                    <p>Google Material Icon: expand_less</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round active">expand_more</span>
                <div>
                    <p class="clarity-small-text">expand more active icon </p>
                    <p>Google Material Icon: expand_more</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">expand_more</span>
                <div>
                    <p class="clarity-small-text">expand more icon </p>
                    <p>Google Material Icon: expand_more</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">quiz</span>
                <div>
                    <p class="clarity-small-text">faqs icon </p>
                    <p>Google Material Icon: quiz</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">filter_alt</span>
                <div>
                    <p class="clarity-small-text">filter icon </p>
                    <p>Google Material Icon: filter_alt</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">folder</span>
                <div>
                    <p class="clarity-small-text">folder icon </p>
                    <p>Google Material Icon: folder</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">folder_open</span>
                <div>
                    <p class="clarity-small-text">folder open icon </p>
                    <p>Google Material Icon: folder_open</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">grid_view</span>
                <div>
                    <p class="clarity-small-text">grid view icon </p>
                    <p>Google Material Icon: grid_view</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">help</span>
                <div>
                    <p class="clarity-small-text">help icon </p>
                    <p>Google Material Icon:help </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">history</span>
                <div>
                    <p class="clarity-small-text">history icon </p>
                    <p>Google Material Icon: history</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">home</span>                
                <div>
                    <p class="clarity-small-text">home icon </p>
                    <p>Google Material Icon: home</p>
                </div>
            </div>            
            <div>
                <span class="material-icons-round">inbox</span>
                <div>
                    <p class="clarity-small-text">inbox icon </p>
                    <p>Google Material Icon: inbox</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">info</span>
                <div>
                    <p class="clarity-small-text">info icon </p>
                    <p>Google Material Icon: info </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">download</span>
                <div>
                    <p class="clarity-small-text">download icon </p>
                    <p> Google Material Icon: download</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">drafts</span>
                <div>
                    <p class="clarity-small-text">drafts icon </p>
                    <p> Google Material Icon: drafts</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">dashboard</span>
                <div>
                    <p class="clarity-small-text">edit dashboard icon </p>
                    <p> Google Material Icon: dashboard</p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">visibility_off</span>
                <div>
                    <p class="clarity-small-text">visibility off icon </p>
                    <p>Google Material Icon: visibility_off </p>
                </div>
            </div>
        </div>
        <div class="col">
            <div>
                <span class="material-icons-round">bar_chart</span>
                <div>
                    <p class="clarity-small-text">investments icon</p>
                    <p>Google Material Icon: bar_chart </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">history_edu</span>
                <div>
                    <p class="clarity-small-text">legacy icon</p>
                    <p>Google Material Icon: history_edu </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">view_list</span>
                <div>
                    <p class="clarity-small-text">list view icon </p>
                    <p>Google Material Icon: view_list </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">question_answer</span>
                <div>
                    <p class="clarity-small-text">live chat icon</p>
                    <p>Google Material Icon: question_answer </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">logout</span>
                <div>
                    <p class="clarity-small-text">logout icon</p>
                    <p>Google Material Icon: logout </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">manage_accounts</span>
                <div>
                    <p class="clarity-small-text">manage account icon</p>
                    <p>Google Material Icon: manage_accounts </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">mark_as_unread</span>
                <div>
                    <p class="clarity-small-text">mark as unread icon</p>
                    <p>Google Material Icon: mark_as_unread </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">menu</span>
                <div>
                    <p class="clarity-small-text">menu icon</p>
                    <p>Google Material Icon: menu </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">remove</span>
                <div>
                    <p class="clarity-small-text">minus icon</p>
                    <p>Google Material Icon: remove </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">more_vert</span>
                <div>
                    <p class="clarity-small-text">more icon</p>
                    <p>Google Material Icon: more_vert </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">radio_button_unchecked</span>
                <div>
                    <p class="clarity-small-text">not important icon</p>
                    <p>Google Material Icon: radio_button_unchecked </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">notifications</span>
                <div>
                    <p class="clarity-small-text">notifications icon</p>
                    <p>Google Material Icon: notifications </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">open_in_new</span>
                <div>
                    <p class="clarity-small-text">open in new icon</p>
                    <p>Google Material Icon: open_in_new </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">lock</span>
                <div> 
                    <p class="clarity-small-text">password icon</p>
                    <p>Google Material Icon: lock </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">phone</span>
                <div>
                    <p class="clarity-small-text">phone icon</p>
                    <p>Google Material Icon: phone </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">play_circle_outline</span>
                <div>
                    <p classs="clarity-small-text">play icon</p>
                    <p>Google Material Icon: play_circle_outline </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">add</span>
                <div>
                    <p class="clarity-small-text">plus icon </p>
                    <p>Google Material Icon: add </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">policy</span>
                <div>
                    <p class="clarity-small-text">policy icon (policy</p>
                    <p>Google Material Icon: bar_chart </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">expand_more</span>
                <div>
                    <p class="clarity-small-text">profile menu icon (expand_more</p>
                    <p>Google Material Icon: bar_chart </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">person_add</span>
                <div>
                    <p class="clarity-small-text">refer friend icon</p>
                    <p>Google Material Icon: person_add </p>
                </div>
            </div>
        </div>
        <div class="col">
            <div>
                <span class="material-icons-round">reply</span>
                <div>
                    <p class="clarity-small-text">reply icon</p>
                    <p>Google Material Icon: reply </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">settings</span>
                <div>
                    <p class="clarity-small-text">self manage icon </p>
                    <p>Google Material Icon: settings </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">send</span>
                <div>
                    <p class="clarity-small-text">sent icon </p>
                    <p>Google Material Icon: send </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round active">send</span>
                <div>
                    <p class="clarity-small-text">sent icon active </p>
                    <p>Google Material Icon: send </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">tune</span>
                <div>
                    <p class="clarity-small-text">settings icon </p>
                    <p>Google Material Icon: tune </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">share</span>
                <div>
                    <p class="clarity-small-text">share icon </p>
                    <p>Google Material Icon: share </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">group_add</span>
                <div>
                    <p class="clarity-small-text">sharing icon </p>
                    <p>Google Material Icon: group_add </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">wb_sunny</span>
                <div>
                    <p class="clarity-small-text">sunny icon </p>
                    <p>Google Material Icon: wb_sunny </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">inventory</span>
                <div>
                    <p class="clarity-small-text">to do list icon </p>
                    <p>Google Material Icon: inventory </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">toggle_on</span>
                <div>
                    <p class="clarity-small-text">toggle icon </p>
                    <p>Google Material Icon: toggle_on </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">delete</span>
                <div>
                    <p class="clarity-small-text">trash icon </p>
                    <p>Google Material Icon: delete </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">unarchive</span>
                <div>
                    <p class="clarity-small-text">unarchive icon </p>
                    <p>Google Material Icon: unarchive </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">cloud_upload</span>
                <div>
                    <p class="clarity-small-text">upload icon </p>
                    <p>Google Material Icon: cloud_upload </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">visibility</span>
                <div>
                    <p class="clarity-small-text">visibility icon </p>
                    <p>Google Material Icon: visibility </p>
                </div>
            </div>
            <div>
            <span class="material-icons-round active">visibility</span>
                <div>
                    <p class="clarity-small-text">visibility icon active</p>
                    <p>Google Material Icon: visibility </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round orange">warning</span>
                <div>
                    <p class="clarity-small-text">warning icon</p>
                    <p>Google Material Icon: warning </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">sentiment_very_satisfied</span>
                <div>
                    <p class="clarity-small-text">smile (very satisfied)</p>
                    <p>Google Material Icon: sentiment_very_satisfied </p>
                </div>
            </div>
          <div>
                <span class="material-icons-round">sentiment_satisfied</span>
                <div>
                    <p class="clarity-small-text">smile (satisfied)</p>
                    <p>Google Material Icon: sentiment_satisfied </p>
                </div>
            </div>
          <div>
                <span class="material-icons-round">sentiment_neutral</span>
                <div>
                    <p class="clarity-small-text">smile (neutral)</p>
                    <p>Google Material Icon: sentiment_neutral </p>
                </div>
            </div>
            <div>
                <span class="material-icons-round">sentiment_dissatisfied</span>
                <div>
                    <p class="clarity-small-text">smile (disatisfied)</p>
                    <p>Google Material Icon: sentiment_dissatisfied </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '@/components/common/Icon';
    export default {
        name: "BrandGuidelineIcons",
        components: {
            Icon
        }
    }
</script>

<style scoped>
    .icon-text {
        margin-bottom: 40px;
    }
    code {
        margin: 0;
        padding: 0;
    }
    .icons p {
        margin: 0;
        padding: 0;
    }
    .col > div {
        display: flex;
        justify-content: flex-start;
        margin: 10px;
    }
    .col > div > span { 
        padding: 20px;
        background-color: var(--clarity-pure-white);
        margin-right: 10px;
        border-radius: 27px;
    }
    .col > div > span:hover {
        color: var(--clarity-blue);
    }
    .col > div > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
</style>