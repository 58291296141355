<template>
  <div>
    <p class="clarity-h6 text-5xl clarity-margin-bottom-m">Inputs</p>

    <p class="clarity-h6 text-3xl clarity-margin-bottom-s">Input Icon Left/Right</p>
    <div>
      <div class="formgrid grid">
        <div class="field col">
          <div class="p-input-icon-left cl-input">
            <i class="pi cl-account-icon" />
            <InputText type="text" placeholder="cl-account-icon" class="p-inputtext-lg w-full" />
          </div>
        </div>
        <div class="field col">
          <div class="p-input-icon-right cl-input">
            <InputText type="text" placeholder="Email" class="p-inputtext-lg" />
            <i class="pi cl-email-icon" />
          </div>
        </div>
        <div class="field col">
          <div class="p-input-icon-right p-input-icon-left cl-input">
            <i class="pi cl-password-icon" />
            <InputText type="text" placeholder="Mixed" class="p-inputtext-lg" />
            <i class="pi cl-email-icon" />
          </div>
        </div>
      </div>
    </div>

    <Panel header="Example Usage" class="clarity-margin-bottom-m">
      <p>This is currently styled to work on a p-inputtext-lg you can use one of the clarity icon classes for the left hand icon. Add the <b>cl-input</b> class to the same element the <b>p-input-icon-left</b> and the clarity style will be applied. </p>
      <p><b>Code Snippet:</b>
        &lt;div class=&quot;p-input-icon-left cl-input&quot;&gt;<br />
          &lt;i class=&quot;pi cl-password-icon&quot; /&gt;<br />
        &lt;InputText type=&quot;text&quot; placeholder=&quot;Placeholder&quot; class=&quot;p-inputtext-lg&quot; /&gt;
        <br /> &lt;/div&gt;</p>
      <p><b>Results In:</b></p>
      <div class="p-input-icon-left cl-input clarity-margin-bottom-s">
        <i class="pi cl-password-icon" />
        <InputText type="text" placeholder="Placeholder" class="p-inputtext-lg" />
      </div>
      <p>For right simply use the <b>p-input-icon-right</b>. Both can be used in combination.</p>
    </Panel>


  </div>
</template>

<script>
export default {
  name: "BrandGuidelineInputs"
}
</script>

<style scoped>

</style>
