<template>
  <div>
    <p class="clarity-h6 text-5xl clarity-margin-bottom-m">Buttons</p>
    <div class="clarity-margin-bottom-m">
      <Button label="clarity-gradient-light-blue" class="mr-2 mb-2 clarity-gradient-light-blue clarity-btn" style="width: 290px;" />
      <Button label="clarity-gradient-dark-grey" class="mr-2 mb-2 clarity-gradient-dark-grey clarity-btn" style="width: 290px;" />
      <Button label="clarity-gradient-greens" class="mr-2 mb-2 clarity-gradient-green clarity-btn" style="width: 290px;" />
      <Button label="clarity-gradient-blue" class="mr-2 mb-2 clarity-gradient-blue clarity-btn" style="width: 290px;" />
      <Button label="clarity-gradient-orange" class="mr-2 mb-2 clarity-gradient-orange clarity-btn" style="width: 290px;" />
      <Button label="clarity-gradient-grey" class="mr-2 mb-2 clarity-gradient-grey clarity-btn" style="width: 290px;" />
      <Button label="Danger" class="p-button-danger mr-2 mb-2 clarity-btn" style="width: 290px;" />
    </div>
    <Panel header="Example Usage" class="clarity-margin-bottom-m">
      <p>Add the class <b>clarity-btn</b> to apply the basic padding and styling as set out in the clarity brand guidelines.</p>
      <p><b>Code Snippet:</b> &lt;Button label=&quot;Button Text&quot; class=&quot;clarity-gradient-light-blue clarity-btn&quot; /&gt;</p>
      <p><b>Results In:</b></p>
      <Button label="Button Text" class="clarity-gradient-light-blue clarity-btn" />
    </Panel>
  </div>
</template>

<script>
export default {
  name: "BrandGuidelineButtons"
}
</script>

<style scoped>

</style>
