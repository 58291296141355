<template>
  <div class="p-8">
    <brand-guideline-buttons />
    <brand-guideline-inputs />
    <brand-guideline-icons />
    <brand-guideline-colours />
    <brand-guideline-typography />
    <prime-vue-components />
  </div>
</template>

<script>

import PrimeVueComponents from "../components/theme/PrimeVueComponents";
import BrandGuidelineColours from "../components/theme/BrandGuidelineColours";
import BrandGuidelineButtons from "../components/theme/BrandGuidelineButtons";
import BrandGuidelineInputs from "../components/theme/BrandGuidelineInputs";
import BrandGuidelineIcons from "../components/theme/BrandGuidelineIcons";
import BrandGuidelineTypography from '../components/theme/BrandGuidelineTypography.vue';
export default {
  name: "ExampleTheme",
  components: {BrandGuidelineInputs, BrandGuidelineButtons, BrandGuidelineColours, PrimeVueComponents, BrandGuidelineIcons, BrandGuidelineTypography},
}
</script>
