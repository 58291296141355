<template>
  <div>
    <h1 class="blue flex-inline my-4">Components</h1>
    <div class="grid input-filled">
      <div class="col-12 lg:col-8">
        <div class="card">
          <h5>Skeleton 'spinner'</h5>
          <div>
            <div class="flex mb-2">
              <Skeleton shape="circle" size="4rem" class="mr-2"></Skeleton>
              <div class="flex flex-column">
                <Skeleton width="10rem" class="mb-2"></Skeleton>
                <Skeleton width="5rem" class="mb-2"></Skeleton>
                <Skeleton height=".5rem"></Skeleton>
              </div>
            </div>
            <Skeleton width="100%" height="150px"></Skeleton>
            <div class="flex justify-content-between mt-3">
              <Skeleton width="4rem" height="2rem"></Skeleton>
              <Skeleton width="4rem" height="2rem"></Skeleton>
            </div>
          </div>
        </div>

      </div>
      <div class="col-12 lg:col-4">
        <div class="card" style="height: 345px">
          <h5>Loading spinner</h5>
          <InputSwitch v-model="isLoading" />
          <p>Fills parent container - can be used with 'v-if' when loading content</p>
          <p>Assumes that the content and Loading component are at the same level of nesting in div</p>
          <div>
            <Loading  v-if="isLoading"/>
            <div v-if="!isLoading">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad natus quod vero laudantium voluptatem quaerat temporibus molestiae, recusandae impedit ipsam quisquam nobis, sit dolore expedita esse ratione cum porro veritatis.
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6  p-fluid">
        <div class="card">
          <h5>InputText</h5>
          <div class="grid formgrid">
            <div class="col-12 mb-2 lg:col-4 mb-lg-0">
              <InputText type="text" placeholder="Default"></InputText>
            </div>
            <div class="col-12 mb-2 lg:col-4 mb-lg-0">
              <InputText type="text" placeholder="Disabled" :disabled="true"></InputText>
            </div>
            <div class="col-12 mb-2 lg:col-4 mb-lg-0">
              <InputText type="text" placeholder="Invalid" class="p-invalid" />
            </div>
          </div>

          <h5>Icons</h5>
          <div class="grid formgrid">
            <div class="col-12 mb-2 lg:col-4 mb-lg-0">
						<span class="p-input-icon-left">
							<i class="pi pi-user" />
							<InputText type="text" placeholder="Username" />
						</span>
            </div>
            <div class="col-12 mb-2 lg:col-4 mb-lg-0">
						<span class="p-input-icon-right">
							<InputText type="text" placeholder="Search" />
							<i class="pi pi-search" />
						</span>
            </div>
            <div class="col-12 mb-2 lg:col-4 mb-lg:col-0">
						<span class="p-input-icon-left p-input-icon-right">
              <i class="pi pi-user" />
							<InputText type="text" placeholder="Search" />
							<i class="pi pi-search" />
						</span>
            </div>
          </div>

          <h5>Float Label</h5>
          <span class="p-float-label">
					<InputText id="username" type="text" v-model="floatValue"/>
					<label for="username">Username</label>
				</span>

          <h5>Textarea</h5>
          <Textarea placeholder="Your Message" :autoResize="true" rows="3" cols="30" />

          <h5>AutoComplete</h5>
          <AutoComplete placeholder="Search" id="dd" :dropdown="true" :multiple="true" v-model="selectedAutoValue" :suggestions="autoFilteredValue" @complete="searchCountry($event)" field="name"/>

          <h5>Calendar</h5>
          <Calendar :showIcon="true" :showButtonBar="true" v-model="calendarValue"></Calendar>

          <h5>Spinner</h5>
          <InputNumber v-model="inputNumberValue" showButtons mode="decimal"></InputNumber>

          <h5>Chips</h5>
          <Chips v-model="chipsValue"/>
        </div>

        <div class="card">
          <h5>Slider</h5>
          <InputText v-model.number="sliderValue" />
          <Slider v-model="sliderValue" />

          <h5>Rating</h5>
          <Rating v-model="ratingValue"/>

          <h5>Input Switch</h5>
          <InputSwitch v-model="switchValue" />
        </div>
      </div>

      <div class="col-12 md:col-6">
        <div class="card">
          <h5>RadioButton</h5>
          <div class="grid">
            <div class="col-12 md:col-4">
              <div class="p-field-radiobutton">
                <RadioButton id="option1" name="option" value="Option 1" v-model="radioValue" />
                <label for="option1">Option 1</label>
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="p-field-radiobutton">
                <RadioButton id="option2" name="option" value="Option 2" v-model="radioValue" />
                <label for="option2">Option 2</label>
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="p-field-radiobutton">
                <RadioButton id="option3" name="option" value="Option 3" v-model="radioValue" />
                <label for="option3">Option 3</label>
              </div>
            </div>
          </div>

          <h5>Checkbox</h5>
          <div class="grid">
            <div class="col-12 md:col-4">
              <div class="p-field-checkbox">
                <Checkbox id="checkOption1" name="option" value="Option 1" v-model="checkboxValue" />
                <label for="checkOption1">Option 1</label>
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="p-field-checkbox">
                <Checkbox id="checkOption2" name="option" value="Option 2" v-model="checkboxValue" />
                <label for="checkOption2">Option 2</label>
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="p-field-checkbox">
                <Checkbox id="checkOption3" name="option" value="Option 3" v-model="checkboxValue" />
                <label for="checkOption3">Option 3</label>
              </div>
            </div>
          </div>
        </div>

        <div class="card  p-fluid">
          <h5>Listbox</h5>
          <Listbox v-model="listboxValue" :options="listboxValues" optionLabel="name" :filter="true"/>

          <h5>Dropdown</h5>
          <Dropdown v-model="dropdownValue" :options="dropdownValues" optionLabel="name" placeholder="Select" />

          <h5>MultiSelect</h5>
          <MultiSelect v-model="multiselectValue" :options="multiselectValues" optionLabel="name" placeholder="Select Countries" :filter="true"></MultiSelect>
        </div>

        <div class="card  p-fluid">
          <h5>ToggleButton</h5>
          <ToggleButton v-model="toggleValue" onLabel="Yes" offLabel="No"/>

          <h5>SelectButton</h5>
          <SelectButton v-model="selectButtonValue1" :options="selectButtonValues1" optionLabel="name" />

          <h5>SelectButton - Multiple</h5>
          <SelectButton v-model="selectButtonValue2" :options="selectButtonValues2" optionLabel="name" :multiple="true"/>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <h5>Buttons</h5>
          <Button label="Submit" class="mr-2 mb-2" />
          <Button icon="pi pi-check" class="mr-2 mb-2" />
          <Button label="Submit" icon="pi pi-check" class="mr-2 mb-2" />
          <Button label="Submit" icon="pi pi-check" iconPos="right" class="mr-2 mb-2" />
          <Button label="Disabled" disabled="disabled" class="mr-2 mb-2" />

          <h5>Severities</h5>
          <Button label="Primary" class="mr-2 mb-2" />
          <Button label="Secondary" class="p-button-secondary mr-2 mb-2" />
          <Button label="Success" class="p-button-success mr-2 mb-2" />
          <Button label="Info" class="p-button-info mr-2 mb-2" />
          <Button label="Warning" class="p-button-warning mr-2 mb-2" />
          <Button label="Help" class="p-button-help mr-2 mb-2" />
          <Button label="Danger" class="p-button-danger mr-2 mb-2" />

          <h5>Raised Buttons</h5>
          <Button label="Primary" class="p-button-raised mr-2 mb-2" />
          <Button label="Secondary" class="p-button-raised p-button-secondary mr-2 mb-2" />
          <Button label="Success" class="p-button-raised p-button-success mr-2 mb-2" />
          <Button label="Info" class="p-button-raised p-button-info mr-2 mb-2" />
          <Button label="Warning" class="p-button-raised p-button-warning mr-2 mb-2" />
          <Button label="Help" class="p-button-raised p-button-help mr-2 mb-2" />
          <Button label="Danger" class="p-button-raised p-button-danger mb-2" />

          <h5>Rounded Buttons</h5>
          <Button label="Primary" class="p-button-rounded mr-2 mb-2" />
          <Button label="Secondary" class="p-button-rounded p-button-secondary mr-2 mb-2" />
          <Button label="Success" class="p-button-rounded p-button-success mr-2 mb-2" />
          <Button label="Info" class="p-button-rounded p-button-info mr-2 mb-2" />
          <Button label="Warning" class="p-button-rounded p-button-warning mr-2 mb-2" />
          <Button label="Help" class="p-button-rounded p-button-help mr-2 mb-2" />
          <Button label="Danger" class="p-button-rounded p-button-danger mb-2" />

          <h5>Text Buttons</h5>
          <Button label="Primary" class="p-button-text mr-2 mb-2" />
          <Button label="Secondary" class="p-button-secondary p-button-text mr-2 mb-2" />
          <Button label="Success" class="p-button-success p-button-text mr-2 mb-2" />
          <Button label="Info" class="p-button-info p-button-text mr-2 mb-2" />
          <Button label="Warning" class="p-button-warning p-button-text mr-2 mb-2" />
          <Button label="Help" class="p-button-help p-button-text mr-2 mb-2" />
          <Button label="Danger" class="p-button-danger p-button-text mr-2 mb-2" />
          <Button label="Plain" class="p-button-text p-button-plain mb-2" />

          <h5>Raised Text Buttons</h5>
          <Button label="Primary" class="p-button-raised p-button-text mr-2 mb-2" />
          <Button label="Secondary" class="p-button-raised p-button-secondary p-button-text mr-2 mb-2" />
          <Button label="Success" class="p-button-raised p-button-success p-button-text mr-2 mb-2" />
          <Button label="Info" class="p-button-raised p-button-info p-button-text mr-2 mb-2" />
          <Button label="Warning" class="p-button-raised p-button-warning p-button-text mr-2 mb-2" />
          <Button label="Help" class="p-button-raised p-button-help p-button-text mr-2 mb-2" />
          <Button label="Danger" class="p-button-raised p-button-danger p-button-text mr-2 mb-2" />
          <Button label="Plain" class="p-button-raised p-button-plain p-button-text mb-2" />

          <h5>Outlined Buttons</h5>
          <Button label="Primary" class="p-button-outlined mr-2 mb-2" />
          <Button label="Secondary" class="p-button-outlined p-button-secondary mr-2 mb-2" />
          <Button label="Success" class="p-button-outlined p-button-success mr-2 mb-2" />
          <Button label="Info" class="p-button-outlined p-button-info mr-2 mb-2" />
          <Button label="Warning" class="p-button-outlined p-button-warning mr-2 mb-2" />
          <Button label="Help" class="p-button-outlined p-button-help mr-2 mb-2" />
          <Button label="Danger" class="p-button-outlined p-button-danger mr-2 mb-2" />
          <Button label="Plain" class="p-button-outlined p-button-plain mb-2" />

          <h5>Rounded Icon Buttons</h5>
          <Button icon="pi pi-check" class="p-button-rounded mr-2 mb-2" />
          <Button icon="pi pi-bookmark" class="p-button-rounded p-button-secondary mr-2 mb-2" />
          <Button icon="pi pi-search" class="p-button-rounded p-button-success mr-2 mb-2" />
          <Button icon="pi pi-user" class="p-button-rounded p-button-info mr-2 mb-2" />
          <Button icon="pi pi-bell" class="p-button-rounded p-button-warning mr-2 mb-2" />
          <Button icon="pi pi-heart" class="p-button-rounded p-button-help mr-2 mb-2" />
          <Button icon="pi pi-times" class="p-button-rounded p-button-danger mb-2" />

          <h5>Rounded Text Icon Buttons</h5>
          <Button icon="pi pi-check" class="p-button-rounded p-button-text mr-2 mb-2" />
          <Button icon="pi pi-bookmark" class="p-button-rounded p-button-secondary p-button-text mr-2 mb-2" />
          <Button icon="pi pi-search" class="p-button-rounded p-button-success p-button-text mr-2 mb-2" />
          <Button icon="pi pi-user" class="p-button-rounded p-button-info p-button-text mr-2 mb-2" />
          <Button icon="pi pi-bell" class="p-button-rounded p-button-warning p-button-text mr-2 mb-2" />
          <Button icon="pi pi-heart" class="p-button-rounded p-button-help p-button-text mr-2 mb-2" />
          <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text mr-2 mb-2" />

          <h5>Rounded and Outlined Icon Buttons</h5>
          <Button icon="pi pi-check" class="p-button-rounded p-button-outlined mr-2 mb-2" />
          <Button icon="pi pi-bookmark" class="p-button-rounded p-button-secondary p-button-outlined mr-2 mb-2" />
          <Button icon="pi pi-search" class="p-button-rounded p-button-success p-button-outlined mr-2 mb-2" />
          <Button icon="pi pi-user" class="p-button-rounded p-button-info p-button-outlined mr-2 mb-2" />
          <Button icon="pi pi-bell" class="p-button-rounded p-button-warning p-button-outlined mr-2 mb-2" />
          <Button icon="pi pi-heart" class="p-button-rounded p-button-help p-button-outlined mr-2 mb-2" />
          <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined mb-2" />

          <h5>Badges</h5>
          <Button type="button" label="Emails" badge="8" class="mr-2 mb-2" />
          <Button type="button" label="Messages" icon="pi pi-users" class="p-button-warning mb-2" badge="8" badgeClass="badge-danger" />

          <h5>Button Set</h5>
          <span class="buttonset">
                    <Button label="Save" icon="pi pi-check" />
                    <Button label="Delete" icon="pi pi-trash" />
                    <Button label="Cancel" icon="pi pi-times" />
                </span>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <DataTable :value="customers" :paginator="true" :rows="10"
                     dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" v-model:filters="filters" filterDisplay="menu"
                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50]"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                     :globalFilterFields="['name','country.name','representative.name','status']" responsiveLayout="scroll">
            <template #header>
              <div class="d-flex jc-between ai-center">
                <h5 class="m-0">Customers</h5>
                <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                            </span>
              </div>
            </template>
            <template #empty>
              No customers found.
            </template>
            <Column selectionMode="multiple" style="min-width: 3rem"></Column>
            <Column field="name" header="Name" sortable style="min-width: 14rem">
              <template #body="{data}">
                {{data.name}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="column-filter" placeholder="Search by name"/>
              </template>
            </Column>
            <Column field="country.name" header="Country" sortable filterMatchMode="contains" style="min-width: 14rem">
              <template #body="{data}">
                <span class="image-text">{{data.country.name}}</span>
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="column-filter" placeholder="Search by country"/>
              </template>
            </Column>
            <Column header="Agent" sortable filterField="representative" sortField="representative.name" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width: 14rem">
              <template #body="{data}">
                <img :alt="data.representative.name" :src="'demo/images/avatar/' + data.representative.image" width="32" style="vertical-align: middle" />
                <span class="image-text">{{data.representative.name}}</span>
              </template>
              <template #filter="{filterModel}">
                <div class="mb-3 text-bold">Agent Picker</div>
                <MultiSelect v-model="filterModel.value" :options="representatives" optionLabel="name" placeholder="Any" class="column-filter">
                  <template #option="slotProps">
                    <div class="multiselect-representative-option">
                      <img :alt="slotProps.option.name" :src="'demo/images/avatar/' + slotProps.option.image" width="32" style="vertical-align: middle" />
                      <span class="image-text">{{slotProps.option.name}}</span>
                    </div>
                  </template>
                </MultiSelect>
              </template>
            </Column>
            <Column field="date" header="Date" sortable dataType="date" style="min-width: 8rem">
              <template #body="{data}">
                {{formatDate(data.date)}}
              </template>
              <template #filter="{filterModel}">
                <Calendar v-model="filterModel.value" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" />
              </template>
            </Column>
            <Column field="balance" header="Balance" sortable dataType="numeric" style="min-width: 8rem">
              <template #body="{data}">
                {{formatCurrency(data.balance)}}
              </template>
              <template #filter="{filterModel}">
                <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
              </template>
            </Column>
            <Column field="status" header="Status" sortable :filterMenuStyle="{'width':'14rem'}" style="min-width: 10rem">
              <template #body="{data}">
                <span :class="'customer-badge status-' + data.status">{{data.status}}</span>
              </template>
              <template #filter="{filterModel}">
                <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Any" class="column-filter" :showClear="true">
                  <template #value="slotProps">
                    <span :class="'customer-badge status-' + slotProps.value">{{slotProps.value}}</span>
                  </template>
                  <template #option="slotProps">
                    <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                  </template>
                </Dropdown>
              </template>
            </Column>
            <Column field="activity" header="Activity" sortable :showFilterMatchModes="false" style="min-width: 10rem">
              <template #body="{data}">
                <ProgressBar :value="data.activity" :showValue="false" />
              </template>
              <template #filter="{filterModel}">
                <Slider v-model="filterModel.value" range class="m-3"></Slider>
                <div class="d-flex ai-center jc-between px-2">
                  <span>{{filterModel.value ? filterModel.value[0] : 0}}</span>
                  <span>{{filterModel.value ? filterModel.value[1] : 100}}</span>
                </div>
              </template>
            </Column>
            <Column headerStyle="min-width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
              <template #body>
                <Button type="button" icon="pi pi-cog"></Button>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="card">
          <h5>AccordionPanel</h5>
          <Accordion :multiple="true" :activeIndex="[0]">
            <AccordionTab header="Header I">
              <p style="margin:0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            </AccordionTab>
            <AccordionTab header="Header II">
              <p style="margin:0">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque
                ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                Consectetur, adipisci velit, sed quia non numquam eius modi.</p>
            </AccordionTab>
            <AccordionTab header="Header III">
              <p style="margin:0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores
                et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.
                Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit
                quo minus.</p>
            </AccordionTab>
          </Accordion>
        </div>
        <div class="card">
          <h5>TabView</h5>
          <TabView>
            <TabPanel header="Header I">
              <p style="margin:0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p></TabPanel>
            <TabPanel header="Header II">
              <p style="margin:0">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque
                ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                Consectetur, adipisci velit, sed quia non numquam eius modi.</p>
            </TabPanel>
            <TabPanel header="Header III">
              <p style="margin:0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores
                et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.
                Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit
                quo minus.</p>
            </TabPanel>
          </TabView>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <div class="card">
          <h5>Panel</h5>
          <Panel header="Header" :toggleable="true">
            <p style="margin:0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          </Panel>
        </div>
        <div class="card">
          <h5>Fieldset</h5>
          <Fieldset legend="Legend" :toggleable="true">
            <p style="margin:0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          </Fieldset>
        </div>
      </div>
      <div class="col-12 md:col-3">
        <div class="card  p-fluid">
          <h5>Overlay Panel</h5>
          <Button type="button" label="Choose" @click="toggle" icon="pi pi-search" />
          <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" style="width: 450px">
            <DataTable :value="products" v-model:selection="selectedProduct" selectionMode="single" :paginator="true" :rows="5" @row-select="onProductSelect">
              <Column field="name" header="Name" sortable></Column>
              <Column header="Image">
                <template #body="slotProps">
                  <img :src="'demo/images/product/' + slotProps.data.image" :alt="slotProps.data.image" class="product-image" />
                </template>
              </Column>
              <Column field="price" header="Price" sortable>
                <template #body="slotProps">
                  {{formatCurrency(slotProps.data.price)}}
                </template>
              </Column>
            </DataTable>
          </OverlayPanel>
        </div>
      </div>
      <div class="col-12 md:col-3">
        <div class="card  p-fluid">
          <h5>Dialog</h5>
          <Dialog header="Dialog" v-model:visible="display" :style="{width: '30vw'}" :modal="true">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
              quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
              in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <template #footer>
              <Button label="Dismiss" @click="close" icon="pi pi-check" class="p-button-text"/>
            </template>
          </Dialog>
          <Button label="Show" icon="pi pi-external-link" @click="open" class="p-button-secondary"/>
        </div>
      </div>
      <div class="col-12 md:col-3">
        <div class="card  p-fluid">
          <h5>Confirm Popup</h5>
          <Button type="button" label="Confirm" @click="confirmWithPopup" class="p-button-success" icon="pi pi-check" />
          <ConfirmPopup group="popup"></ConfirmPopup>
        </div>
      </div>
      <div class="col-12 md:col-3">
        <div class="card  p-fluid">
          <h5>Confirm Dialog</h5>
          <Button type="button" label="Confirm" @click="confirmWithDialog" class="p-button-help" icon="pi pi-check" />
          <ConfirmDialog group="dialog">></ConfirmDialog>
        </div>
      </div>
      <div class="col-12">
        <div class="card card-w-title">
          <h5>Menubar</h5>
          <Menubar :model="tieredMenuItems">
            <template #end>
						<span class="p-input-icon-left">
							<i class="pi pi-search" />
							<InputText type="text" placeholder="Search" />
						</span>
            </template>
          </Menubar>
        </div>
      </div>
      <div class="col-12 md:col-4">
        <div class="card">
          <h5>Tiered Menu</h5>
          <TieredMenu :model="tieredMenuItems" />
        </div>
      </div>

      <div class="col-12 md:col-4">
        <div class="card">
          <h5>Plain Menu</h5>
          <Menu :model="menuitems" />
        </div>
      </div>

      <div class="col-12 md:col-4">
        <div class="card">
          <h5>Overlay Menu</h5>

          <Menu ref="menu" :model="menuitems" :popup="true" />
          <Button type="button" label="Options" icon="pi pi-angle-down" @click="toggleMenu" style="width: auto"/>
        </div>

        <div class="card" @contextmenu="onContextRightClick">
          <h5>ContextMenu</h5>
          Right click to display.
          <ContextMenu ref="contextMenu" :model="contextMenuItems" />
        </div>
      </div>

      <div class="col-12 lg:col-6">
        <div class="card">
          <h5>Messages</h5>
          <Message severity="info">Info Message</Message>
          <Message severity="success">Success Message</Message>
          <Message severity="warn">Warning Message</Message>
          <Message severity="error">Error Message</Message>
        </div>
      </div>

      <div class="col-12 lg:col-6">
        <div class="card">
          <h5>Toast</h5>

          <Toast />
          <Button @click="showToast('info')" label="Info" class="p-button-info mr-2 mb-2" />
          <Button @click="showToast('success')" label="Success" class="p-button-success mr-2 mb-2" />
          <Button @click="showToast('warn')" label="Warn" class="p-button-warning mr-2 mb-2" />
          <Button @click="showToast('error')" label="Error" class="p-button-danger mb-2" />
        </div>

        <div class="card">
          <h5>Timeline</h5>
          <Timeline :value="timelineEvents" align="alternate">
            <template #content="slotProps">
              {{slotProps.item.status}}
            </template>
          </Timeline>
        </div>
      </div>

      <div class="col-12 lg:col-8">
        <div class="card">
          <h5>Inline Message</h5>
          <div class="formgroup-inline" style="margin-bottom:.5rem">
            <label for="username1" class="p-sr-only">Username</label>
            <InputText id="username1" type="text" placeholder="Username" class="p-invalid mr-2" />
            <InlineMessage>Username is required</InlineMessage>
          </div>
          <div class="formgroup-inline">
            <label for="email" class="p-sr-only">email</label>
            <InputText id="email" placeholder="Email" class="p-invalid mr-2" />
            <InlineMessage />
          </div>
        </div>
      </div>

      <div class="col-12 lg:col-4">
        <div class="card">
          <h5>Helper Text</h5>
          <div class="p-field  p-fluid">
            <label for="username2">Username</label>
            <InputText id="username2" type="text" aria-describedby="username-help" />
            <small id="username-help" class="p-error">Enter your username to reset your password.</small>
          </div>
        </div>
      </div>
    </div>
    <h1 class="blue flex-inline my-4">Components Cards</h1>
        <ErrorCard>
        <template v-slot:content>
            <h1 class="card-title">We couldn't find this page</h1>
            <div class="card-footer">
                <span class="p-input-icon-left cl-input w-full">
                <i class="pi pi-search"></i>
                <InputText type="text" placeholder="Search..." class="p-inputtext-lg w-full" />
                </span>
            </div>
        </template>
        <template v-slot:image>
            <img class="middle" src="@/assets/img/oops-sitting.svg" alt="ooops">
        </template>
    </ErrorCard>
  </div>
</template>

<script>
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import ErrorCard from '@/components/common/ErrorCard'
export default {
    name: "PrimeVueComponents",
    data() {
        return {
            customers: null,
            selectedCustomers: null,
            products: null,
            selectedProduct: null,
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'country.name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
                'representative': {value: null, matchMode: FilterMatchMode.IN},
                'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
                'balance': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                'activity': {value: null, matchMode: FilterMatchMode.BETWEEN},
                'verified': {value: null, matchMode: FilterMatchMode.EQUALS}
            },
            floatValue: null,
            autoValue: null,
            selectedAutoValue: null,
            autoFilteredValue: [],
            calendarValue: null,
            inputNumberValue: null,
            chipsValue: null,
            sliderValue: null,
            ratingValue: null,
            colorValue: '1976D2',
            radioValue: null,
            checkboxValue: [],
            switchValue: false,
            display: false,
            listboxValues: [
                {name: 'New York', code: 'NY'},
                {name: 'Rome', code: 'RM'},
                {name: 'London', code: 'LDN'},
                {name: 'Istanbul', code: 'IST'},
                {name: 'Paris', code: 'PRS'}
            ],
            listboxValue: null,
            dropdownValues: [
                {name: 'New York', code: 'NY'},
                {name: 'Rome', code: 'RM'},
                {name: 'London', code: 'LDN'},
                {name: 'Istanbul', code: 'IST'},
                {name: 'Paris', code: 'PRS'}
            ],
            dropdownValue: null,
            multiselectValue: null,
            multiselectValues: [
                {name: 'Australia', code: 'AU'},
                {name: 'Brazil', code: 'BR'},
                {name: 'China', code: 'CN'},
                {name: 'Egypt', code: 'EG'},
                {name: 'France', code: 'FR'},
                {name: 'Germany', code: 'DE'},
                {name: 'India', code: 'IN'},
                {name: 'Japan', code: 'JP'},
                {name: 'Spain', code: 'ES'},
                {name: 'United States', code: 'US'}
            ],
            toggleValue: false,
            selectButtonValues1: [
                {name: 'Option 1', code: 'O1'},
                {name: 'Option 2', code: 'O2'},
                {name: 'Option 3', code: 'O3'},
            ],
            selectButtonValue1: null,
            selectButtonValues2: [
                {name: 'Option 1', code: 'O1'},
                {name: 'Option 2', code: 'O2'},
                {name: 'Option 3', code: 'O3'},
            ],
            selectButtonValue2: null,
			tieredMenuItems: [
				{
					label:'Customers',
					icon:'pi pi-fw pi-table',
					items:[
						{
							label:'New',
							icon:'pi pi-fw pi-user-plus',
							items:[
								{
									label:'Customer',
									icon:'pi pi-fw pi-plus'
								},
								{
									label:'Duplicate',
									icon:'pi pi-fw pi-copy'
								},

							]
						},
						{
							label:'Edit',
							icon:'pi pi-fw pi-user-edit'
						}
					]
				},
				{
					label:'Orders',
					icon:'pi pi-fw pi-shopping-cart',
					items:[
						{
							label:'View',
							icon:'pi pi-fw pi-list'
						},
						{
							label:'Search',
							icon:'pi pi-fw pi-search'
						},

					]
				},
				{
					label:'Shipments',
					icon:'pi pi-fw pi-envelope',
					items:[
						{
							label:'Tracker',
							icon:'pi pi-fw pi-compass'

						},
						{
							label:'Map',
							icon:'pi pi-fw pi-mamarker'

						},
						{
							label:'Manage',
							icon:'pi pi-fw pi-pencil'
						}
					]
				},
				{
					label:'Profile',
					icon:'pi pi-fw pi-user',
					items:[
						{
							label:'Settings',
							icon:'pi pi-fw pi-cog'
						},
						{
							label:'Billing',
							icon:'pi pi-fw pi-file'
						}
					]
				},
				{
					separator:true
				},
				{
					label:'Quit',
					icon:'pi pi-fw pi-sign-out'
				}
			],
			menuitems: [
				{
					label:'Customers',
					items:[
						{
							label:'New',
							icon:'pi pi-fw pi-plus',
						},
						{
							label:'Edit',
							icon:'pi pi-fw pi-user-edit'
						}
					]
				},
				{
					label:'Orders',
					items:[
						{
							label:'View',
							icon:'pi pi-fw pi-list'
						},
						{
							label:'Search',
							icon:'pi pi-fw pi-search'
						},

					]
				}
			],
			contextMenuItems: [
				{
					label: 'Save',
					icon: 'pi pi-save'
				},
				{
					label: 'Update',
					icon: 'pi pi-refresh'
				},
				{
					label: 'Delete',
					icon: 'pi pi-trash'
				},
				{
					separator: true
				},
				{
					label: 'Options',
					icon: 'pi pi-cog'
				},
        ],
        message: [],
        timelineEvents: [
            {status: 'Ordered', date: '15/10/2020 10:30', icon: 'pi pi-shopping-cart', color: '#9C27B0', image: 'game-controller.jpg'},
            {status: 'Processing', date: '15/10/2020 14:00', icon: 'pi pi-cog', color: '#673AB7'},
            {status: 'Shipped', date: '15/10/2020 16:15', icon: 'pi pi-shopping-cart', color: '#FF9800'},
            {status: 'Delivered', date: '16/10/2020 10:00', icon: 'pi pi-check', color: '#607D8B'}
        ],
        isLoading: false
        }
    },
    countryService: null,
    methods: {
        toggleMenu(event) {
			this.$refs.menu.toggle(event);
        },
        onContextRightClick(event) {
            this.$refs.contextMenu.show(event);
        },
        searchCountry(event) {
            setTimeout(() => {
                if (!event.query.trim().length) {
                    this.autoFilteredValue = [...this.autoValue];
                }
                else {
                    this.autoFilteredValue = this.autoValue.filter((country) => {
                        return country.name.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }
            }, 250);
        },
        addMessage(severity) {
            this.message = [{severity: severity, content: 'Message Detail'}]
        },
        showToast(severity) {
            this.$toast.add({severity: severity, summary: 'Message Summary', detail:'Message Detail', life: 3000});
        },
        open() {
			this.display = true;
		},
		close() {
			this.display = false;
        },
        toggle(event) {
			this.$refs.op.toggle(event);
        },
        confirmWithPopup(event) {
            this.$confirm.require({
                target: event.currentTarget,
                group: 'popup',
                message: 'Are you sure you want to proceed?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.$toast.add({severity:'info', summary:'Confirmed', detail:'You have accepted', life: 3000});
                },
                reject: () => {
                    this.$toast.add({severity:'info', summary:'Rejected', detail:'You have rejected', life: 3000});
                }
            });
        },
        confirmWithDialog() {
            this.$confirm.require({
                group: 'dialog',
                header: 'Confirm',
                message: 'Are you sure you want to proceed?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.$toast.add({severity:'info', summary:'Confirmed', detail:'You have accepted', life: 3000});
                },
                reject: () => {
                    this.$toast.add({severity:'info', summary:'Rejected', detail:'You have rejected', life: 3000});
                }
            });
        },
        onProductSelect(event) {
            this.$refs.op.hide();
            this.$toast.add({severity:'info', summary: 'Product Selected', detail: event.data.name, life: 3000});
        },
        formatDate(value) {
            return value.toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
        },
        toggleLoading() {
          this.toggleLoading = !this.toggleLoading
        }
    },
    components: {
      ErrorCard
    }
}
</script>

<style scoped lang="scss">
.image-text {
    vertical-align: middle;
}

.progressbar {
    height: 4px;
}

.customer-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;

    &.status-qualified {
        background-color: #C8E6C9;
        color: #256029;
    }

    &.status-unqualified {
        background-color: #FFCDD2;
        color: #C63737;
    }

    &.status-negotiation {
        background-color: #FEEDAF;
        color: #8A5340;
    }

    &.status-new {
        background-color: #B3E5FC;
        color: #23547B;
    }

    &.status-renewal {
        background-color: #ECCFFF;
        color: #694382;
    }

    &.status-proposal {
        background-color: #FFD8B2;
        color: #805B36;
    }
}

.product-image {
    width: 50px;
    box-shadow: 0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23);
}

.card :deep(.paginator) {
    .paginator-current {
        margin-left: auto;
    }
}
</style>
