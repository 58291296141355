<template>
  <div>
    <p class="clarity-h6 text-5xl clarity-margin-bottom-m">COLOURS</p>
    <div class="grid">
      <div class="col">
        <p class="clarity-h4 clarity-margin-bottom-s">SOLID COLOURS</p>
        <div class="grid">
          <div class="col">
            <div class="flex flex-wrap" >
              <div v-for="colour in whites" :key="colour" class="flex flex-row" style="min-width: 300px;">
                <div class="flex align-items-center justify-content-center m-2 border-round border-white border-2 shadow-3" :class="colour.class" style="min-width: 100px; min-height: 100px"></div>
                <div class="flex-grow-1 flex align-items-center m-2 font-bold pl-5">
                  <div>
                    .{{ colour.class }}<br>
                    {{ colour.code }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="flex flex-wrap" >
              <div v-for="colour in colours" :key="colour" class="flex flex-row" style="min-width: 300px;">
                <div class="flex align-items-center justify-content-center m-2 border-round border-white border-2 shadow-3" :class="colour.class" style="min-width: 100px; min-height: 100px"></div>
                <div class="flex-grow-1 flex align-items-center m-2 font-bold pl-5">
                    <div>
                    .{{ colour.class }}<br>
                    {{ colour.code }}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <p class="clarity-h4 clarity-margin-bottom-s">GRADIENT COLOURS</p>
        <div class="grid">
          <div class="col">
            <div class="flex flex-wrap" >
              <div v-for="colour in gradients" :key="colour" class="flex flex-row" style="min-width: 500px;">
                <div class="flex align-items-center justify-content-center m-2 border-round border-white border-2 shadow-3" :class="colour.class" style="min-width: 100px; min-height: 100px"></div>
                <div class="flex-grow-1 flex align-items-center m-2 font-bold pl-5">
                  <div>
                    .{{ colour.class }}<br>
                    {{ colour.code }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p class="clarity-h6 text-5xl clarity-margin-bottom-m">Shadows</p>

    <Panel header="Example Usage" class="clarity-margin-bottom-m">

      <p>Please make use of the primeflex shadow classes when applying a shadow to an element on the page. For small elements like buttons etc make use of <b>shadow-2</b> and for larger items like cards make use of <b>shadow-6</b></p>

      <p><b>Code Snippet:</b>
        &lt;Button label=&quot;Primary&quot; class=&quot;p-button-text mr-2 mb-2 shadow-2&quot; /&gt;<br />
        &lt;Button label=&quot;Primary&quot; class=&quot;p-button-text mr-2 mb-2&quot; /&gt;</p>
      <p><b>Results In:</b></p>
      <div  class="clarity-margin-bottom-m">
        <Button label="Primary" class="p-button-text mr-2 mb-2 shadow-2" />
        <Button label="Primary" class="p-button-text mr-2 mb-2" />
      </div>


      <p><b>Code Snippet:</b> &lt;Card class=&quot;shadow-6 m-2&quot; style=&quot;width: 300px&quot;&gt;&lt;/Card&gt; <br />
        &lt;Card style=&quot;width: 300px&quot;&gt;&lt;/Card&gt;
      </p>
      <p><b>Results In:</b></p>
      <Card class="shadow-6 m-2" style="width: 300px"></Card>
      <Card style="width: 300px"></Card>
    </Panel>
  </div>
</template>

<script>
export default {
  name: "BrandGuidelineColours",
  data() {
    return {
      whites: [
        { class: 'clarity-pure-white', code: '#FFFFFF'},
        { class: 'clarity-snow-grey', code: '#F2F4F6'},
        { class: 'clarity-light-grey', code: '#DCE1E6'},
        { class: 'clarity-grey', code: '#8B96A2'},
        { class: 'clarity-dark-grey', code: '#282B2F'},
      ],
      colours: [
        { class: 'clarity-sky-blue', code: '#AEDCF1'},
        { class: 'clarity-light-blue', code: '#179BD7'},
        { class: 'clarity-blue', code: '#0058A9'},
        { class: 'clarity-green', code: '#00B04C'},
        { class: 'clarity-dark-green', code: '#019567'},
        { class: 'clarity-red', code: '#D13138'},
        { class: 'clarity-dark-orange', code: '#D95D03'},
        { class: 'clarity-orange', code: '#F18903'}
      ],
      gradients: [
        { class: 'light-grey', code: '#FFFFFF - #F2F4F6'},
        { class: 'grey', code: '#F2F4F6 - #DCE1E6'},
        { class: 'dark-grey', code: '#666666 - #282B2F'},
        { class: 'light-blue', code: '#7CC2E2 - #179BD7'},
        { class: 'blue', code: '#179BD7 - #0058A9'},
        { class: 'green', code: '#00B04C - #019567'},
        { class: 'orange', code: '#F18903 - #D95D03'}
      ]
    }
  }
}
</script>

<style scoped>

</style>
